<template>
  <div class="row">
    <div class="col d-flex align-items-center justify-content-between">
      <div
        class="font-21"
      >
        Set tours limit
      </div>
      <ElSwitch
        v-model="uiFlags.isBudget"
        class="budget-switch"
        :width="44"
        @change="toggleBudget"
      />
    </div>
    <transition name="fade">
      <div
        class="col-12 pt-0"
      >
        <slot name="alert" />
        <p
          class="font-15 mt-3"
        >
          Automatically pause showings after a pre-defined number of paid tours have been completed.
          No-shows or tours you take over are not counted towards the limit.
          Note that we will not be able to cancel tours on short notice.
        </p>
        <el-input-number
          v-show="uiFlags.isBudget"
          v-model="budget"
          class="mt-2"
          :min="minimumBudget"
          :max="99"
          @change="updateBudget"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import { reactive, ref } from '@vue/composition-api';

export const defaultBudget = 10;
export default {
  name: 'ShowingProfileToursBudget',
  components: {},
  props: {
    currentBudget: {
      type: Number,
      required: false,
      default: defaultBudget,
    },
    minimumBudget: {
      type: Number,
      required: false,
      default: 1,
    },
  },
  setup(props, context) {
    const uiFlags = reactive({ isBudget: Boolean(props.currentBudget) });
    const budget = ref(props.currentBudget);
    updateBudget(budget.value);

    return {
      uiFlags, budget, toggleBudget, updateBudget,
    };

    function toggleBudget(enabled) {
      updateBudget(enabled ? budget.value : null);
    }

    function updateBudget(value) {
      context.emit('update-budget', value);
    }
  },
};

</script>

<style lang="scss" scoped>
</style>
