<template>
  <div>
    <ElRadioGroup
      v-model="showingProfilePayload.access_hardware"
      class="mb-2"
      @change="emitAccessHardwareSelect"
    >
      <ElRadioButton
        v-for="accessHardware in enabledAccessHardwareOptions"
        :key="accessHardware.value"
        :label="accessHardware.value"
        :disabled="isDisabled(accessHardware)"
      >
        {{ accessHardware.label }}
      </ElRadioButton>
    </ElRadioGroup>

    <ElAlert
      v-if="codeboxChanged"
      type="warning"
      show-icon
      :closable="false"
      class="mt-2 mb-3"
    >
      <div>
        It will take up to an hour to refresh the access codes for all scheduled tours.
        <span v-if="impact.length">
          You have {{ impact.length }} upcoming tours that may start before we are able to generate new codes,
          which would result in prospects being unable to access the unit.
        </span>
      </div>
    </ElAlert>

    <ElAlert
      v-if="displayContractorLockboxWarning"
      type="warning"
      show-icon
      tag-class="tagline-warning-light"
      :closable="false"
      class="mt-2 mb-3"
    >
      <span>
        Warning: Providing prospects with a single, static code for self-showings means that anyone can access the property at any time,
        leaving you an easy target for scams. Please proceed with caution.
      </span>
    </ElAlert>

    <ElFormItem
      v-if="showingProfilePayload.access_hardware === AccessHardware.CODEBOX"
      :prop="showingProfile.id ? 'showing_profile.codebox_serial_number' : 'codebox_serial_number'"
      :rules="!showingProfile.codebox_serial_number && showingProfile.access_hardware === AccessHardware.CODEBOX
        ? rules.codebox_serial_number
        : []"
      class="mt-2"
    >
      <CodeboxAutoComplete
        :selected-unit-id="unit.codebox_serial_number ? unit.id : null"
        :payload="showingProfilePayload"
        @set-codebox="setCodebox"
      />
    </ElFormItem>

    <ElFormItem
      v-if="showingProfilePayload.access_hardware !== AccessHardware.CODEBOX"
      :prop="showingProfile.id ? 'showing_profile.lock_box_code' : 'lock_box_code'"
      :rules="rules.lock_box_code"
    >
      <SdFloatLabel :label="AccessHardwareInputPlaceholders[showingProfilePayload.access_hardware]">
        <ElInput
          v-model="showingProfilePayload.lock_box_code"
          maxlength="64"
          :placeholder="AccessHardwareInputPlaceholders[showingProfilePayload.access_hardware]"
          @input="emitShowingProfileStateChanged"
        />
      </SdFloatLabel>
    </ElFormItem>

    <ElFormItem
      :prop="showingProfile.id ? 'showing_profile.comments' : 'comments'"
      :rules="property.market_id ? rules.comments : []"
      class="mt-4"
    >
      <ElTooltip
        :visible-arrow="false"
        :disabled="$viewport.lt.md"
        popper-class="popover-panel"
        placement="right-start"
      >
        <template slot="content">
          <i class="sdicon-info-circle" />
          <p>
            Here you can add any helpful information {{ isSelfShowing ? 'prospects' : 'agents' }} should know.
            <br>
            <br>
            For example: Where is the lockbox? Any property highlights? How much are utilities?
          </p>
        </template>
        <SdFloatLabel>
          <ElInput
            v-model="showingProfilePayload.comments"
            type="textarea"
            :rows="4"
            :maxlength="1024"
            :placeholder="accessCommentsPlaceholder"
            @input="emitShowingProfileStateChanged"
          />
        </SdFloatLabel>
      </ElTooltip>
    </ElFormItem>

    <ElFormItem
      v-if="!isSelfShowing && isPropertyMultiUnit"
      prop="common_agent_notes"
    >
      <ElTooltip
        :visible-arrow="false"
        :disabled="$viewport.lt.md"
        popper-class="popover-panel"
        placement="right-start"
        class="mt-3"
      >
        <template slot="content">
          <i class="sdicon-info-circle" />
          <p>
            These instructions will apply to all currently showing units in this property.
            For example a main gate access code.
          </p>
        </template>
        <SdFloatLabel>
          <ElInput
            v-model="propertyPayload.common_agent_notes"
            type="textarea"
            :rows="4"
            maxlength="1024"
            placeholder="Property-wide instructions"
            @input="emitPropertyStateChanged"
          />
        </SdFloatLabel>
      </ElTooltip>
    </ElFormItem>
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api';
import { formatImpact } from '@/utils/ShowingProfileFormatImpact';
import { showErrorNotify } from '@/utils/NotifyUtil';
import CodeboxAutoComplete from '@/components/showing-profile/CodeboxAutoComplete';
import {
  AccessHardware,
  AccessHardwareInputPlaceholders,
  AccessHardwareOptions,
  ContractorAccessHardware,
} from '@/constants/AccessHardware';

const rules = {
  lock_box_code: {
    required: 'true',
    type: 'string',
    message: 'Code cannot be blank',
  },
  codebox_serial_number: {
    required: 'true',
    type: 'number',
    message: 'Codebox cannot be blank',
  },
  comments: {
    required: 'true',
    type: 'string',
    message: 'Notes to agents cannot be blank',
  },
};

export default {
  name: 'ShowingProfileAccessInstructions',
  components: {
    CodeboxAutoComplete,
  },
  props: {
    business: {
      type: Object,
      required: true,
    },
    showingProfile: {
      type: Object,
      required: true,
    },
    property: {
      type: Object,
      required: true,
    },
    unit: {
      type: Object,
      required: false,
      default: null,
    },
    isEditFlow: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, context) {
    const isSelfShowing = props.showingProfile.is_self_showing;
    const showingProfilePayload = ref({ ...props.showingProfile });
    const propertyPayload = ref({ ...props.property });
    const deactivationImpactRequestSent = ref(false);
    const isContractorLockboxEnabled = ref(!!props.business.self_showing_contractor_lockbox_enabled_at);
    const enabledAccessHardwareOptions = computed(computedEnabledAccessHardwareOptions);
    const accessCommentsPlaceholder = computed(() => `Notes for the ${isSelfShowing ? 'prospects' : 'agents'}`);
    const displayContractorLockboxWarning = computed(() => (isSelfShowing && showingProfilePayload.value.access_hardware !== AccessHardware.CODEBOX));
    const codeboxChanged = ref(false);
    const impact = ref({});
    const isPropertyMultiUnit = computed(() => props.property.units.length > 1);

    return {
      AccessHardware,
      AccessHardwareOptions,
      AccessHardwareInputPlaceholders,
      setCodebox,
      emitAccessHardwareSelect,
      emitPropertyStateChanged,
      emitShowingProfileStateChanged,
      isDisabled,
      isPropertyMultiUnit,
      isSelfShowing,
      showingProfilePayload,
      propertyPayload,
      enabledAccessHardwareOptions,
      codeboxChanged,
      impact,
      accessCommentsPlaceholder,
      displayContractorLockboxWarning,
      rules,
    };

    function computedEnabledAccessHardwareOptions() {
      const isContractorLockboxAvailable = (!isSelfShowing || isContractorLockboxEnabled.value);
      const isCodeboxEnabled = props.business.codebox_connected;

      const codeboxFilter = (option) => (isCodeboxEnabled && option.value === AccessHardware.CODEBOX);

      const contractorLockboxFilter = (option) => (
          isContractorLockboxAvailable
          && ContractorAccessHardware.includes(option.value)
      );

      return AccessHardwareOptions.filter((option) => codeboxFilter(option) || contractorLockboxFilter(option));
    }

    function isDisabled(accessHardware) {
      if (props.isEditFlow && props.showingProfile.access_hardware !== AccessHardware.CODEBOX) {
        return false;
      }

      return props.isEditFlow
          && showingProfilePayload.value.access_hardware === AccessHardware.CODEBOX
          && accessHardware.value !== AccessHardware.CODEBOX;
    }

    function emitAccessHardwareSelect() {
      if (showingProfilePayload.value.access_hardware === AccessHardware.CODEBOX) {
        showingProfilePayload.value.lock_box_code = null;
      } else {
        showingProfilePayload.value.codebox_serial_number = null;
        codeboxChanged.value = false;
        if (props.showingProfile.lock_box_code) {
          showingProfilePayload.value.lock_box_code = props.showingProfile.lock_box_code;
        }
      }

      emitShowingProfileStateChanged();
    }

    function emitPropertyStateChanged() {
      context.emit('update-property-payload', { common_agent_notes: propertyPayload.value.common_agent_notes });
    }

    function emitShowingProfileStateChanged() {
      context.emit('update-showing-profile-payload', {
        lock_box_code: showingProfilePayload.value.lock_box_code,
        comments: showingProfilePayload.value.comments,
        access_hardware: showingProfilePayload.value.access_hardware,
        codebox_serial_number: showingProfilePayload.value.codebox_serial_number,
      });
    }

    async function setCodebox(serialNumber) {
      if (props.isEditFlow && serialNumber !== props.unit.codebox_serial_number) {
        try {
          if (!deactivationImpactRequestSent.value) {
            const dispatch = context.root.$store.dispatch;
            const impactResponse = await dispatch('ShowingProfile/deactivationImpact', {
              showingProfileId: props.showingProfile.id,
            });
            deactivationImpactRequestSent.value = true;
            const { nonCancellable } = formatImpact(context, impactResponse);
            impact.value = [...nonCancellable];
          }
          codeboxChanged.value = true;
        } catch (error) {
          showErrorNotify(context, error.message);
        }
      } else {
        codeboxChanged.value = false;
      }
      showingProfilePayload.value.codebox_serial_number = serialNumber;
      emitShowingProfileStateChanged();
    }
  },
};
</script>

<style lang="scss"></style>
